import request from "../../../api/request";

export const fetch =()=>(dispatch)=> {
  const data = request(`/products`, {
    method: 'get',
    data:""
  }).then(res=>{
    dispatch({
      type: "VOUCHERS",
      payload: res?.products,
    });
  });
  console.log('data ---', data);
  return data;
}

export const remove = (id) => (dispatch) => {
  console.log('id ---', id);
  return request(`/products/${id}`, {
    method: 'delete',
  }).then(res=>{
    dispatch(fetch())
    dispatch({
      type: "VOUCHERS",
      payload: res?.products,
    });
  });
}

export const patch = (id, values) => (dispatch) =>  {
  console.log('edit action', id, values);
   return request(`/products/${id._id}`, {
    method: 'PATCH',
    data: {
      // id: id?._id,
      email: values.email,
      discount: values.discount,
      voucher: values.voucher,
      validity: values.validity,
      validityDate: values.validityDate,
      productType: values.productType,
      productName: values.productName,
      brandName: values.brandName,
      minPrice: values.minPrice,
      valid: true
    },
  }).then(res=>{
    dispatch(fetch())
    dispatch({
      type: "VOUCHERS",
      payload: res?.products,
    });
  });
}

export const create = (values) => (dispatch) => {
  console.log('values in action', values);
  return request('/products', {
    method: 'post',
    data: {
      email: values.email,
      discount: values.discount,
      voucher: values.voucher,
      validity: values.validity,
      validityDate: values.validityDate,
      productType: values.productType,
      productName: values.productName,
      brandName: values.brandName,
      minPrice: values.minPrice,
      valid: true
    },
  }).then(res=>{
    dispatch(fetch())
    dispatch({
      type: "VOUCHERS",
      payload: res?.products,
    });
  });
}

export const sendEmail =(values)=>(dispatch)=> {
  return request('/sendEmail', {
    method: 'post',
    data: {
      email: values.email,
      discount: values.discount,
      voucher: values.voucher
    },
  });
}
