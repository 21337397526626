/* eslint-disable no-unused-vars */
// import { Router as router } from "@mui/icons-material";
import request from '../api/request'

export const loginAction = (data) => (dispatch) => {
  console.log("data in actions", data);
  const resp = request('/login', {
    method: 'post',
    data: {
      email: data.email,
      password: data.password
    },
  }).then(res => {
    if (res) {
      sessionStorage.setItem('isLoggedin', true);
      return dispatch({
        type: "LOGIN_USER",
        payload: data,
      });
    } else {
      const loginError = false;
      console.log('catch an error');
      
      return dispatch({
          type: "LOGIN_USER_ERROR",
          payload: {
            loginError: true,
          },
        });;
    }
  }).catch(err=>{
    console.log('catch an error ---1');
  });

  // console.log('res---login```', resp.then(resp=>console.log(resp)));
  // router.push()
  // return dispatch({
  //   type: "LOGIN_USER",
  //   payload: data,
  // });
};
export const registerAction = (data) => (dispatch) => {
  console.log("data in actions", data);
  const res = request('/register', {
    method: 'post',
    data: {
      email: data.email,
      username: data.username,
      password: data.password
    },
  });
  console.log('response--register', res);
  // return dispatch({
  //   type: "REGISTER_USER",
  //   payload: data,
  // });
};

