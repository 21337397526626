import React from 'react';
import { Route, Redirect } from 'react-router-dom';
// import { isLogin } from '../utils';

const PrivateRoutes = ({component: Component, ...rest}) => {

    // console.log('rest props', rest);
    const { loggedIn } = rest;
    return (
        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            loggedIn ?
                <Component {...props} />
            : <Redirect to="/" />
        )} />
    );
};

export default PrivateRoutes;