import axios from "axios";
// import { notification } from "antd";

// const openNotificationWithIcon = (type, message) => {
//   notification[type]({
//     message: message,
//   });
// };

// function checkStatus(response) {
//   if (response?.status >= 200 && response?.status < 300) {
//     // return openNotificationWithIcon('success');
//   } else if (response === undefined) {
//     // console.log('got error');
//     // openNotificationWithIcon('error');
//   }
// }

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */

const getAPiUrl =()=>{
  if(window.location.origin === 'http://localhost:3000' || window.location.origin ===  'http://localhost:3001') {
    return 'http://localhost:4000';
  } else {
    return 'https://qavoucherify-backend.enetdefender.com';
  }
}

export default async function request(url, options) {
  const response = await axios({
    method: options.method,
    url: `${getAPiUrl() + url}`,
    // url: `${'https://qavoucherify-backend.enetdefender.com'+url}`,
    data: options.data,
    //headers: {"Content-Type":"application/json"}
  })
    .then((res) => {
      // checkStatus(res);
        // if (res?.status === 200) {
        //   openNotificationWithIcon("success", res?.data?.message);
        // }
      return res.data;
    })
    .catch((err) => {
      console.log('error', err);
    });
  return response;
}
