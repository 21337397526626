import React from "react";
import { PageHeader } from "antd";
import { Content } from "antd/lib/layout/layout";

const PageHead = ({ title, subTitle, breadcrumbs, children, bgNone, button }) => {
  const routes = breadcrumbs.map((route) => route);
  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={title}
        subTitle={subTitle}
        breadcrumb={{ routes }}
        extra={button && button}
      >
        <Content className={bgNone ? `p-4` : `bg-white p-4`}>{children}</Content>
      </PageHeader>
    </div>
  );
};

export default PageHead;