import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd'
import React, { useState } from 'react'

const Budget = ({title, fieldName, show, setFormValues}) => {
    const [showDIv, setShowDiv] = useState(show)

    return (
      <div className="flex pb-4">
        <div className='pr-2 text-lg font-medium'>{title}</div>
        <div className='pr-2 text-lg text-gray-400'>
          <i>is less than or equal</i>
        </div>
        {showDIv ? (
          <div className='pl-2'>
            <Form.Item name={fieldName}>
              <input
                type="text"
                onChange={e => setFormValues(fieldName, e.target.value)}
                size="large"
                style={{ width: "50%" }}
              />
              <span className='pl-2 cursor-pointer' onClick={()=>setShowDiv(false)}><CloseOutlined /></span>
            </Form.Item>
          </div>
        ) : (
          <div className='px-4 rounded-md cursor-pointer hover:bg-gray-200' onClick={()=>setShowDiv(true)}>
              <EditOutlined /> <span className='py-2 text-lg'>Set</span>
          </div>
        )}
      </div>
    );
}

export default Budget
