/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Row } from 'antd'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'

const CompaignTemplate = () => {
    const history = useHistory();

    const redirectUrl =(e, campaign)=>{
        e.preventDefault();
        console.log('came in!!', campaign);
        history.push({
          pathname: `/campaign/create/${campaign.url}`,
          state: {
            campaignName: campaign.title,
          },
        });
    }

    const campaignType = [
        {
            id: 1,
            title: 'Discount Coupons',
            url: 'discountCoupon',
            imageSrc: "https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/64/000000/external-coupon-offline-marketing-inipagistudio-mixed-inipagistudio.png",
            subTitle: 'Personal use or public channels',
            description: 'Issue one-off, personalised discount codes for personal use (e.g. HELLO4543) or publish a public fixed-code voucher across your messaging channels (e.g. BlackFriday19)'
        },
        {
            id: 2,
            title: 'Gift Vouchers',
            url: 'giftVouchers',
            imageSrc: "https://img.icons8.com/external-vitaliy-gorbachev-blue-vitaly-gorbachev/60/000000/external-coupon-ecommerce-vitaliy-gorbachev-blue-vitaly-gorbachev.png",
            subTitle: 'Personal use or public channels',
            description: 'Publish digital gift cards as unique one-off codes aimed at particular customers or launch a public, reusable gift card certificates that act as digital wallets'
        },
        {
            id: 3,
            title: 'Referral Codes',
            url: 'referralCodes',
            imageSrc: "https://img.icons8.com/external-vitaliy-gorbachev-lineal-color-vitaly-gorbachev/60/000000/external-share-social-media-vitaliy-gorbachev-lineal-color-vitaly-gorbachev-1.png",
            subTitle: 'Friends refer friends',
            description: 'Multi-tiered referral program with rewards for referrers and invited customers (e.g., $20 for you and every invited friend, $50 if you get more than five)'
        },
        {
            id: 4,
            title: 'Promotion',
            url: 'promotion',
            imageSrc: "https://img.icons8.com/external-inipagistudio-mixed-inipagistudio/64/000000/external-promotion-online-marketing-strategy-inipagistudio-mixed-inipagistudio.png",
            subTitle: 'Discounts without code',
            description: 'Automatically applied discount based on shopping cart structure and customer attributes (e.g., 5% off if total order amount exceeds $200)'
        },
        {
            id: 5,
            title: 'Loyalty Program',
            url: 'loyaltyProgram',
            imageSrc: "https://img.icons8.com/ios-filled/64/000000/like.png",
            subTitle: 'Rewards for points',
            description: 'Use points to motivate action at every stage of your customer journey'
        }
    ]



    return (
        <Row gutter={6} className='row'>
            {campaignType.map(campaign => (
                <Col lg={8}>
                    <div className="max-w-sm p-6 mt-6 bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
                        <img alt="" src={campaign?.imageSrc} />
                        <p className="mt-2 text-sm font-normal tracking-tight text-gray-600 dark:text-white">{campaign.subTitle}</p>
                        <a href="#">
                            <h5 className="mt-4 mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{campaign.title}</h5>
                        </a>
                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{campaign.description}</p>
                        <div className='cursor-pointer' >
                            <a href="#" onClick={(e)=> redirectUrl(e, campaign)} className="inline-flex float-right px-3 py-2 text-sm font-medium text-right text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                Create
                                <svg className="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                            </a>
                        </div>
                    </div>
                </Col>
            )
            )}
        </Row>
    )
}

export default CompaignTemplate
