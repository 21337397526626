import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Switch, DatePicker } from "antd";
import { patch } from "../services/users";
import { useDispatch } from "react-redux";
// import { useForm } from "rc-field-form";

// const label = Form.Item;

function EditUserModal(props) {
  const { onOk, visible, record } = props;

  const dispatch = useDispatch();
  //   const form = useForm()

  const [productDetails, setProductDetails] = useState(false);
  const [validity, setValidity] = useState(false);
  const [selectedData, setSelectedData] = useState({
    email: "",
    voucher: "",
    discount: "",
    productType: "",
    productName: "",
    brandName: "",
    minPrice: "",
    validityDate: "",
    validity: "",
  });

  useEffect(() => {
    if (record) {
      setSelectedData({
        email: record.email,
        voucher: record.voucher,
        discount: record.discount,
        productType: record.productType,
        productName: record.productName,
        brandName: record.brandName,
        minPrice: record.minPrice,
        validityDate: record.validityDate,
        validity: record.validity,
      });
    }
  }, [record]);
  const [formValues, setFormvalues] = useState();

  useEffect(() => {
    if (selectedData) {
      setFormvalues(selectedData);
    }
  }, [selectedData]);

  const formInputHandleChange = (name, value) => {
    setFormvalues({ ...formValues, [name]: value });
  };

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  console.log("form values", formValues);
  const onFinish = async () => {
    onOk(false);
    if (formValues.email !== "" || formValues.voucher !== "" | formValues.discount !== "") {
      await dispatch(patch(record, formValues));
      onOk();
    } else {
      Promise.reject('Please fill the required fields!')
    }
  };
  return (
    <div>
      <Modal
        title={
          <span className="font-bold text-lg" onClick={onOk}>
            Update voucher
          </span>
        }
        visible={visible}
        onCancel={() => onOk(false)}
        footer={false}
      >
        <form onSubmit={onFinish}>
          <label>Email </label>
          <input
            size="large"
            type="text"
            placeholder="Enter email."
            name="email"
            onChange={(e) => formInputHandleChange("email", e.target.value)}
            style={{ width: "100%" }}
            className="mb-4"
            defaultValue={selectedData?.email || ""}
          />


          <label>Voucher</label>
          {console.log(selectedData?.voucher)}
          <input
            type="text"
            onChange={(e) => formInputHandleChange("voucher", e.target.value)}
            size="large"
            placeholder="Enter voucher."
            className="mb-4"
            style={{ width: "100%" }}
            defaultValue={selectedData?.voucher || ""}
          />
          <label
            label="Discount"
            name="discount"
            rules={[
              {
                required: true,
                message: "Please enter discount!",
              },
            ]}
          >
            Discount
          </label>
          {console.log(selectedData?.discount)}
          <input
            onChange={(e) => formInputHandleChange("discount", e.target.value)}
            type="number"
            size="large"
            placeholder="Enter discount."
            className="mb-4"
            style={{ width: "100%" }}
            defaultValue={selectedData?.discount || ""}
          />
          <div className="mb-6 shadow-md px-4 py-2">
            <span className="mr-4 text-black text-md">Product Details</span>{" "}
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked={false}
              onChange={() => setProductDetails(!productDetails)}
            />
          </div>
          {productDetails && (
            <>
              <Row gutter="6">
                <Col lg={12} md={12}>
                  <label label="Product type" name="productType">
                    Product tyle
                  </label>
                  {console.log(selectedData?.productType)}
                  <input
                    onChange={(e) =>
                      formInputHandleChange("productType", e.target.value)
                    }
                    type="text"
                    size="large"
                    className="mb-4"
                    placeholder="Ex. Bumper etc."
                    style={{ width: "100%" }}
                    defaultValue={selectedData?.productType || ""}
                  />
                </Col>
                <Col lg={12} md={12}>
                  <label label="Product Name" name="productName">
                    {console.log(selectedData?.productName)}
                    Product name
                  </label>
                  <input
                    onChange={(e) =>
                      formInputHandleChange("productName", e.target.value)
                    }
                    size="large"
                    type="text"
                    className="mb-4"
                    placeholder="Ex. Engine mount"
                    style={{ width: "100%" }}
                    defaultValue={selectedData?.productName || ""}
                  />
                </Col>
              </Row>

              <Row gutter="6">
                <Col lg={12} md={12}>
                  <label label="Brand Name" name="brandName">
                    {console.log(selectedData?.brandName)}
                    Brand name
                  </label>
                  <input
                    onChange={(e) =>
                      formInputHandleChange("brandName", e.target.value)
                    }
                    size="large"
                    type="text"
                    className="mb-4"
                    placeholder="Ex. Audi etc."
                    style={{ width: "100%" }}
                    defaultValue={selectedData?.brandName || ""}
                  />
                </Col>
                <Col lg={12} md={12}>
                  <label label="Min. Price" name="minPrice">
                    {console.log(selectedData?.minPrice)}
                    Min. pric
                  </label>
                  <input
                    onChange={(e) =>
                      formInputHandleChange("minPrice", e.target.value)
                    }
                    size="large"
                    type="number"
                    placeholder="In doller"
                    className="mb-4"
                    style={{ width: "100%" }}
                    defaultValue={selectedData?.minPrice || ""}
                  />
                </Col>
              </Row>
            </>
          )}

          <div className="mb-4 shadow-md px-4 py-2">
            <span className="mr-4 text-black text-md">Valid till</span>{" "}
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked={false}
              onChange={() => setValidity(!validity)}
            />
          </div>
          {validity && (
            <>
              <Row gutter="6">
                <Col lg={12} md={12}>
                  <label
                    label="Validity date"
                    name="validityDate"
                    style={{ width: "100%" }}
                    defaultValue={selectedData?.validityDate || ""}
                  >
                    {console.log(selectedData?.validityDate)}
                    Validity date
                  </label>
                  <DatePicker
                    size="large"
                    onChange={() => onChange}
                    className="mb-4"
                    style={{ width: "100%", marginBottom: '1rem' }}
                    defaultValue={selectedData?.validityDate || ""}
                  />
                </Col>
                <Col lg={12} md={12}>
                  <label label="Validity" name="validity">
                    Valid hours
                  </label>
                  <input
                    onChange={(e) =>
                      formInputHandleChange("validity", e.target.value)
                    }
                    size="large"
                    type="number"
                    placeholder="In hours"
                    className="mb-4"
                    style={{ width: "100%" }}
                    defaultValue={selectedData?.validity || ""}
                  />
                </Col>
              </Row>
            </>
          )}
          <Row style={{ float: "right" }}>
            <Col>
              <span className="float-right -mt-3 mb-8 text-right">
                <Button type="primary" size="medium" onClick={onFinish} htmlType="submit">
                  {"Update"}
                </Button>
              </span>
            </Col>
          </Row>
        </form>
      </Modal>
    </div>
  );
}

export default EditUserModal;
