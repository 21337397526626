import { Button, Col, Form, Input, Row, Tabs, Select } from "antd";
import React from "react";
import Info from "../../../../Comman/Info/Info";
const { TabPane } = Tabs;
const { Option } = Select;

const AttributeMatch = ({ setFormValues }) => {
  const setProperty =(e)=>{
    setFormValues('propertyAttributeOrder', e)
  }
  return (
    <div className="mb-4 space-x-4">
      <Tabs defaultActiveKey="1" centered={false}>
        <TabPane
          tab={<span className="text-lg font-medium">Order Metadata</span>}
          key="1"
        >
          <Info>
            Extend Voucherify with custom fields like payment_mean,
            booking_start_date, booking_end_date, location_id etc., so that you
            can fully customize your campaigns
          </Info>
          <Row gutter={6}>
            <Col lg={7}>
              <span className="w-full -mb-4">Select property</span>
              <Form.Item name="orderProperty">
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Select property"
                  onChange={(e) => setProperty(e)}
                >
                  <Option value="property1">Property 1</Option>
                  <Option value="property2">Property 2</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={7}>
              <span className="w-full -mb-4">Condition</span>
              <Form.Item name="orderCondition">
                <input
                  type="text"
                  onChange={e => setFormValues('propertyAttributeCondition', e.target.value)}
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Condition"
                />
              </Form.Item>
            </Col>
            <Col lg={7}>
              <span className="w-full -mb-4">Value</span>
              <Form.Item name="orderValue">
                <input
                  type="number"
                  onChange={e => setFormValues('propertyAttributeValue', e.target.value)}
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Value"
                />
              </Form.Item>
            </Col>
            <Col lg={3}>
              <div className="mt-6">
                <Button type="primary" size="large">
                  Add
                </Button>
              </div>
            </Col>
          </Row>
        </TabPane>
        <TabPane
          tab={<span className="text-lg font-medium">Redemption Metadata</span>}
          key="2"
        >
          <Info>
            Extend Voucherify with custom fields like location_id etc. so that
            you can fully customize your campaigns
          </Info>
          <Row gutter={6}>
            <Col lg={7}>
              <span className="w-full -mb-4">Select property</span>
              <Form.Item name="metadataProperty">
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Select property"
                  onChange={(e) => setFormValues('metaDataProperty', e)}
                >
                  <Option value="property1">Property 1</Option>
                  <Option value="property2">Property 2</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={7}>
              <span className="w-full -mb-4">Condition</span>
              <Form.Item name="metadataCondition">
                <input
                  type="text"
                  onChange={e => setFormValues('metaDataCondition', e.target.value)}
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Condition"
                />
              </Form.Item>
            </Col>
            <Col lg={7}>
              <span className="w-full -mb-4">Value</span>
              <Form.Item name="metadataValue">
                <input
                  type="number"
                  onChange={e => setFormValues('metaDataValue', e.target.value)}
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Value"
                />
              </Form.Item>
            </Col>
            <Col lg={3}>
              <div className="mt-6">
                <Button type="primary" size="large">
                  Add
                </Button>
              </div>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default AttributeMatch;
