import React from 'react'

const DiscountCard = ({i, item, onClick, selectedDiv}) => {
    return (
        <div>
            <div onClick={() => onClick()}>
                <div className={`${(i) && 'ml-2'} items-center w-48 h-52 rounded-xl cursor-pointer hover:border-2 hover:border-blue-400 ${selectedDiv === item.discountType ? 'border-2 border-blue-400' : 'border-2 border-gray-200'} shadow-md p-8 mt-4 lg:mt-2`}>
                    <div className={`flex justify-end -mr-2 -mt-2 ${selectedDiv === item.discountType ? 'visible' : 'invisible'}`}>
                        <button id="dropdownButton" data-dropdown-toggle="dropdown" className="hidden sm:inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5" type="button">
                            <img alt="" className="w-6 h-6 rounded-full" src="https://img.icons8.com/external-tal-revivo-color-tal-revivo/24/000000/external-simple-line-tick-or-checkmark-in-box-votes-color-tal-revivo.png" />
                        </button>
                    </div>
                    <img alt="" className="flex items-center w-12 h-12 mx-auto mb-3 bg-center rounded-full shadow-lg" src={item.imageSrc} />
                    <div className="mb-1 text-sm font-medium text-center text-gray-900 dark:text-white">{item.discountType}</div>
                    <div className="text-xs text-center text-gray-500 dark:text-gray-400">{item.subTitle}</div>
                </div>
            </div>
        </div>
    )
}

export default DiscountCard
