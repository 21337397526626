/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  ArrowDownOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input, Radio, Row, Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import DiscountCard from "../../../../Comman/DiscountCard/DiscoutCard";
import Info from "../../../../Comman/Info/Info";
import AddOrder from "../AddOrder/AddOrder";
import AttributeMatch from "../Attribute/AttributeMatch";
import Budget from "../Budget/Budget";
import DiscountToFilter from "../DiscountToFilter/DiscountToFilter";
const { Option } = Select;

const AmountType = ({ setFormValues }) => {
  const [selectedDiscountTo, setSelectedDiscountTo] = useState("wholeCart");
  const [segmentSelection, setSegmentSelection] = useState("Customer activity");
  const [createValidation, setCreateValidation] = useState(true);
  const [selectedValidation, setSelectedValidation] = useState("Customer activity");


  const changeDiscount = (e) => {
    setSelectedDiscountTo(e.target.value)
    setFormValues('discountType', e.target.value)
    console.log('values on change--', e.target.value);
    if (e.target.value !== 'wholeCart') {
      setCreateValidation(false)
    } else {
      setCreateValidation(true)
    }
  }


  const renderInfoText = useCallback(() => {
    switch (selectedDiscountTo) {
      case "wholeCart":
        return "whole Cart – Discount will be applied to the total order amount.";
      case "items":
        return "items – Discount will be applied to the products below. Each product subtotal will be discounted equally.";
      case "amountProportionally":
        return "amount proportionally – Discount will be applied to the products below. Each item will be discounted proportionally based on its share in the total order amount.";
      case "quantityProportionally":
        return "quantity proportionally – Discount will be applied to the products below. Each item will be discounted proportionally based on its quantity share in the total order items quantity.";
      default:
        return "whole Cart – Discount will be applied to the total order amount.";
    }
  }, [selectedDiscountTo]);

  const validationType = [
    {
      imageSrc: "https://img.icons8.com/ios-filled/50/000000/user.png",
      discountType: "Customer activity",
      subTitle: "(e.g. cart abandoned)",
    },
    {
      imageSrc: "https://img.icons8.com/glyph-neue/64/000000/stack.png",
      discountType: "Attributes match",
      subTitle: "(e.g. 10% off)",
    },
    {
      imageSrc:
        "https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/64/000000/external-dollar-banking-and-finance-kiranshastry-lineal-kiranshastry-6.png",
      discountType: "Budget",
      subTitle: "(e.g. total order value)",
    },
    {
      imageSrc: "https://img.icons8.com/dotty/80/000000/create-order.png",
      discountType: "Order",
      subTitle: "(e.g. total amount)",
    },
  ];

  const renderValidationType = useCallback(() => {
    switch (selectedValidation) {
      case "Customer activity":
        return (
          <Row gutter={12}>
            <Col lg={12}>
              <span className="w-full">Condition</span>
              <Form.Item name="customerSegment">
                <Select
                  size="large"
                  style={{ width: "100%" }}
                  placeholder="Active only on"
                  onChange={(e) => setSegmentSelection(e)}
                >
                  <Option value="inSegment">Customer is in segment</Option>
                  <Option value="notInSegment">
                    Customer is not in segment
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col lg={12}>
              <div className="mt-5">
                <Form.Item name="">
                  <Button type="primary" size="large">
                    Add
                  </Button>
                </Form.Item>
              </div>
            </Col>
          </Row>
        );
      case "Attributes match":
        return <AttributeMatch  setFormValues={setFormValues}/>;
      case "Budget":
        return (
          <>
            <Budget setFormValues={setFormValues} title="Total orders value" show={false} />
            <Budget
              title="Total discounted amount"
              fieldName={"amountDiscountValue"}
              show={false}
              setFormValues={setFormValues}
            />
            <Budget
              setFormValues={setFormValues}
              title="Total number of redemptions"
              fieldName={"amountRedemptionValue"}
              show={false}
            />
            <div className="mt-6">
              <div className="pb-4 text-lg font-medium">
                Redemptions per customer
              </div>
              <div className="border-l-2 border-gray-400 border-dashed">
                <div className="pl-4">
                  <Budget
                    setFormValues={setFormValues}
                    title="Per code"
                    fieldName={"amountDiscountRedemptionValue"}
                    show={false}
                  />
                  <Budget
                    setFormValues={setFormValues}
                    title="Per code per day"
                    fieldName={"amountDiscountPerCodePerDayValue"}
                    show={false}
                  />
                  <Budget
                    setFormValues={setFormValues}
                    title="In campaign"
                    fieldName={"amountDiscountCampaignValue"}
                    show={false}
                  />
                </div>
              </div>
            </div>
          </>
        );
      case "Order":
        return (
          <>
            <div className="">
              <Row gutter={6}>
                <Col lg={8}>
                  <div className="pt-2 text-lg font-semibold">Total amount</div>
                </Col>
                <Col lg={6}>
                  <div className="pl-2">
                    <Form.Item name="orderCondition">
                      <Select
                        size="large"
                        style={{ width: "100%" }}
                        placeholder="Condition"
                        onChange={(e) => "setSegmentSelection(e)"}
                      >
                        <Option value="isLessThan">is less than</Option>
                        <Option value="IsExactly">is exactly</Option>
                        <Option value="isMoreThan">is more than</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="pl-2">
                    <Form.Item name="orderCondition">
                      <Input placeholder="Value (USD)" />
                    </Form.Item>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="pl-2">
                    <Button type="primary" size="large">
                      Add
                    </Button>
                  </div>
                </Col>
              </Row>
              <div>
                <AddOrder orderType="Any" title="Any order item" />
                <AddOrder orderType="Every" title="Every order item" />
              </div>
            </div>
          </>
        );
      default:
        return "whole Cart – Discount will be applied to the total order amount.";
    }
  }, [selectedValidation]);

  return (
    <>
      <div className="p-4 bg-white">
        <Row gutter={12}>
          <Col lg={24}>
            <span className="w-full -mb-4">Discount Value (USD)</span>
            <Form.Item name="discountUSD">
              <Input
                size="large"
                style={{ width: "100%" }}
                placeholder="Discount Value (USD)"
              />
            </Form.Item>
          </Col>
          <Col lg={24}>
            <div className="w-full mb-2 text-lg">Apply discount to</div>
            <Form.Item name="discountTo">
              <Radio.Group
                size="large"
                defaultValue={selectedDiscountTo}
                onChange={(e) => changeDiscount(e)}
                buttonStyle="solid"
              >
                <Radio.Button value="wholeCart">Whole cart</Radio.Button>
                <Radio.Button value="items">Items</Radio.Button>
                <Radio.Button value="amountProportionally">
                  Amount proportionally
                </Radio.Button>
                <Radio.Button value="quantityProportionally">
                  Quantity proportionally
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={24}>
            {selectedDiscountTo && (
              <Info>
                <span>Apply to </span> {renderInfoText()}
                {selectedDiscountTo !== "wholeCart" && (
                  <div className="flex">
                    <div className="mt-1 mr-2">
                      <ArrowDownOutlined />
                    </div>
                    <div className="mt-2 font-bold">
                      Choose discounted products
                    </div>
                  </div>
                )}
              </Info>
            )}
          </Col>
        </Row>
      </div>
      <div className="mb-8">
        {selectedDiscountTo === "wholeCart" && (
          <div className="px-4 py-8 mt-4 bg-white">
            <Info>
              Validation rules help you define promotions’ eligibility
              conditions based on audience, order structure, order volume,
              budget constraints,and others - <a href="#">read more</a>
            </Info>
          </div>
        )}
        {createValidation ? (
          <div className="flex px-4 py-8 mt-4 bg-white">
            <div>
              <Button
                type=""
                onClick={() => setCreateValidation(false)}
                size="large"
              >
                Create validation rule
              </Button>
            </div>
            <div className="lg:ml-4">
              <Button type="" size="large">
                Select existing validation rule
              </Button>
            </div>
          </div>
        ) : (
          <div className="flex py-8 mt-4">
            <div>
              <Button type="" size="large">
                <EditOutlined /> Use advance builder
              </Button>
            </div>
            <div className="lg:ml-4">
              <Button
                type=""
                onClick={() => setCreateValidation(true)}
                size="large"
              >
                Quit builder
              </Button>
            </div>
            <div className="lg:ml-4">
              <Button type="" size="large">
                <ExclamationCircleOutlined /> Get help
              </Button>
            </div>
          </div>
        )}

        {!createValidation && <DiscountToFilter />}

        {!createValidation && (
          <div className="flex px-4 py-8 mt-4 bg-white">
            <div className="flex flex-wrap">
              {validationType.map((item, i) => (
                <DiscountCard
                  i={i}
                  item={item}
                  selectedDiv={selectedValidation}
                  onClick={() => setSelectedValidation(item.discountType)}
                />
              ))}
            </div>
          </div>
        )}

        {!createValidation && (
          <div className="px-4 py-8 mt-4 bg-white">
            {renderValidationType()}
          </div>
        )}
      </div>
    </>
  );
};

export default AmountType;
