import React, { useEffect, useState } from 'react';
import {
  Switch,
  Route,
  useLocation
} from 'react-router-dom';

import './css/style.scss';

import { focusHandling } from 'cruip-js-toolkit';
import './charts/ChartjsConfig';

// Import pages
import Dashboard from './pages/Dashboard';
import LoginDashboard from './pages/LoginDashboard';
import { useSelector } from 'react-redux';
// import Maps from './pages/Maps';
import Vouchers from './Components/Vouchers/components/Users'
import PrivateRoutes from './Components/PrivateRoutes/PrivateRoutes';
import CreateCampainTypes from './Components/Compaign/CreateCampainTypes';
import CampaignCreator from './Components/Compaign/CampainCreator/CampaignCreator';
import CampaignWrapper from './Components/PostCampaign/CampaignWrapper/CampaignWrapper';

function App() {
  const userData = useSelector(state => state.rootReducer)
  const [isLogin, setIslogin] = useState(false);
  const location = useLocation();


  useEffect(() => {
    if(userData.isLogin) {
      setIslogin(true)
    } else {
      setIslogin(false)
    }
  }, [userData])

  useEffect(() => {
   const loginData = sessionStorage.getItem('isLoggedin')
   if(loginData === 'true'){
    setIslogin(true)
   } else {
    setIslogin(false)
   }
   console.log('get the login details',loginData === 'true', loginData);
  }, [])


  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change



  return (
    <>
      <Switch>
        <Route exact path="/">
        {!isLogin ? <LoginDashboard /> : <Dashboard /> }
        </Route>
        <PrivateRoutes component={Vouchers} loggedIn={isLogin} path="/vouchers" exact />
        <PrivateRoutes component={CreateCampainTypes} loggedIn={isLogin} path="/campaign" exact />
        <PrivateRoutes component={CampaignCreator} loggedIn={isLogin} path="/campaign/create/:voucherType" exact />
        <PrivateRoutes component={CampaignWrapper} loggedIn={isLogin} path="/core/campaign/:campaignId/:tabs" exact />
      </Switch>
    </>
  );
}

export default App;
