import { PlusOutlined } from '@ant-design/icons';
import { Form, Radio } from 'antd';
import React from 'react';
import Info from '../../../../Comman/Info/Info';

const DiscountToFilter = () => {

    const onSelectDiscount=(e)=>{
        console.log('Select:', e.target.value);
    }

    return <div className='px-4 py-4 mt-4 bg-white'>
        <div className='py-2'>
            <div className='flex space-x-2'>
                <div className='leading-5 pt-2 font-medium text-lg'>Discount</div>
                <div>
                    <Form.Item name="discountTo">
                        <Radio.Group
                            size="large"
                            defaultValue={'Each'}
                            onChange={(e) => onSelectDiscount(e)}
                            buttonStyle="solid"
                        >
                            <Radio.Button value="Each">Each</Radio.Button>
                            <Radio.Button value="Selected">Selected</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </div>
                <div className='leading-5 pt-2 font-medium text-lg'>order line item(s)</div>
                <div className='pt-2 font-medium text-lg px-2 py-1 hover:bg-gray-200 cursor-pointer rounded-md' style={{height:'45px'}}> 
                <span className='pr-2'><PlusOutlined /></span>
                add products</div>
            </div>

            <div className='flex space-x-2 mb-4'>
                <div className='leading-5 pt-4 font-medium text-lg'>Exclude</div>
                <div className='pt-2 font-normal text-lg px-2 py-1 hover:bg-gray-200 cursor-pointer rounded-md' style={{height:'45px'}}> 
                <span className='pr-2'><PlusOutlined /></span>
                add products</div>
            </div>
        </div>
        <div className='py-2'>
            <Info>
                Added products will be discounted only if all validation rules are met
            </Info>
        </div>
        <div>
            <Info>
                The excluded products rule has priority over included products rule. If the excluded collection contains included items, then a discount will not be granted to those items.
            </Info>
        </div>
    </div>;
};

export default DiscountToFilter;
