/* eslint-disable jsx-a11y/anchor-is-valid */
import Form from 'antd/lib/form/Form'
import React, { useState } from 'react'
import DiscountCard from '../../Comman/DiscountCard/DiscoutCard'
import AmountType from './DiscountValue/AmountType/AmountType'
import FixedAmountType from './DiscountValue/FixedAmountType/FixedAmountType'
import FreeShipping from './DiscountValue/FreeShipping/FreeShipping'
import PercentageType from './DiscountValue/PercentageType/PercentageType'
import UnitType from './DiscountValue/UnitType/UnitType'

const CampainThirdStep = ({ setFormValues }) => {

    const [selectedDiv, setSelectedDiv] = useState('Amount type')

    const discountType = [
        {
            imageSrc: 'https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/64/000000/external-dollar-banking-and-finance-kiranshastry-lineal-kiranshastry-6.png',
            discountType: 'Amount type',
            subTitle: '(e.g. $10.00 off)'
        },
        {
            imageSrc: 'https://img.icons8.com/cotton/64/000000/percentage--v1.png',
            discountType: 'Percentage type',
            subTitle: '(e.g. 10% off)'
        },
        {
            imageSrc: 'https://img.icons8.com/external-vitaliy-gorbachev-lineal-vitaly-gorbachev/60/000000/external-dollar-currency-vitaliy-gorbachev-lineal-vitaly-gorbachev-2.png',
            discountType: 'Fixed amount',
            subTitle: '(e.g. cart total is $20.00)'
        },
        {
            imageSrc: 'https://img.icons8.com/external-wanicon-lineal-wanicon/64/000000/external-glucose-meter-health-checkup-wanicon-lineal-wanicon.png',
            discountType: 'Unit type',
            subTitle: '(e.g. time, square meters)'
        },
        {
            imageSrc: 'https://img.icons8.com/dotty/80/000000/one-free.png',
            discountType: 'Free shipping',
            subTitle: ''
        }
    ]

    const renderDiscountType = () => {
        switch (selectedDiv) {
            case 'Amount type':
                return <AmountType setFormValues={setFormValues} />;
            case 'Percentage type':
                return <PercentageType setFormValues={setFormValues} />;
            case 'Fixed amount':
                return <FixedAmountType setFormValues={setFormValues} />;
            case 'Unit type':
                return <UnitType setFormValues={setFormValues} />;
            case 'Free shipping':
                return <FreeShipping setFormValues={setFormValues} />;
            default:
                return 'Amount type';
        }
    }
    return (
      <div className="">
        <div className="flex flex-wrap p-4 bg-white content-evenly">
          {/* <Form.Item name="discountType"> */}
            {discountType.map((item, i) => (
              <DiscountCard
                i={i}
                item={item}
                selectedDiv={selectedDiv}
                onClick={() => setSelectedDiv(item.discountType)}
              />
            ))}
          {/* </Form.Item> */}
        </div>
        <div className="mt-8">{renderDiscountType()}</div>
      </div>
    );
}

export default CampainThirdStep
