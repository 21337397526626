/* eslint-disable jsx-a11y/anchor-is-valid */
import { MinusCircleOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Row, Space } from 'antd';
import React, { useState } from 'react';
import Info from '../../../../Comman/Info/Info';

const MetaData = () => {
    const [metadata, setMetadata] = useState(true);
    // const onFinish = values => {
    //     console.log('Received values of form:', values);
    // };

    return (
        <div className='p-4 bg-white'>
            <Form.Item
                name="metadataChecked"
                valuePropName="metadataChecked"
            >
                <Checkbox size='large' checked={metadata} onChange={e => setMetadata(e.target.checked)}>
                    Use the voucher's metadata schema.
                </Checkbox>
            </Form.Item>
            <div className='my-4'>
                {metadata &&
                    <Info>
                        This option enabled will pursue that the defined metadata will be set in the generated vouchers automatically. Updating metadata will update vouchers aggregated in the campaign, it will affect all vouchers which are not published or redeemed yet.
                    </Info>
                }
            </div>
            <div>
                <Info>
                    Metadata structure must be consistent with a scheme you have defined in the settings
                    <a href='#' className='pl-4 font-bold text-md'>
                        <span className='pr-2'><SettingOutlined /></span>Project settings
                    </a>
                </Info>
            </div>
            <div className='py-2 font-medium border-b-2 border-gray-200 text-md'>
                <Row gutter={6}>
                    <Col lg={12}>Key</Col>
                    <Col lg={12}>Value</Col>
                </Row>
            </div>
            <div className='py-4'>
                <Form.List name="users">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'key']}
                                        rules={[{ required: true, message: 'Missing key' }]}
                                    >
                                        <Input style={{width: '100%'}} placeholder="Key" />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'value']}
                                        rules={[{ required: true, message: 'Missing value' }]}
                                    >
                                        <Input style={{width: '100%'}} placeholder="Value" />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <div className=''>
                                <div className='w-1/4 leading-4'>
                                    <Form.Item>
                                        <Button type="dashed" size='large' onClick={() => add()} block icon={<PlusOutlined />}>
                                            Add next property
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </>
                    )}
                </Form.List>
            </div>
        </div>
    );
};

export default MetaData;
