/* eslint-disable no-redeclare */
import { Checkbox, Col, Form, Input, Radio, Row, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import Info from '../../Comman/Info/Info';
import { useSelector } from 'react-redux';

const { Option } = Select;
const { TextArea } = Input;

const CampainFirstStep = ({ onChange, value, setFormValues }) => {
    const [showUpdateInfo, setShowUpdateInfo] = useState(false)
    const [showLimit, setShowLimit] = useState(false)
    const [showAdvanceSetting, setShowAdvanceSetting] = useState(false)
    const firstStep = useSelector(state => state.vouchers);
    const [form] = Form.useForm();

    // useEffect(()=> {
    //   console.log('form----', form.getFieldValue('programName'));
    //   form.setFields([
    //     {
    //       name: 'programName',
    //       value: 'hello world',
    //       errors: ['errorMsg'],
    //     },
    //   ]);
    // }, [])

    const limitChange = (e) => {
        setShowLimit(e.target.checked)
        setFormValues('programLimit', e.target.checked)
    }

    const settingChange = (e) => {
        setShowAdvanceSetting(e.target.checked)
        setFormValues('advanceSetting', e.target.checked)
    }
    const updateChange = (e) => {
        setShowUpdateInfo(e.target.checked);
        setFormValues('updateChange', e.target.checked);
    }

    function onChangeSearch(value) {
        console.log(`selected ${value}`);
        setFormValues('programCategory', value)
    }
    function onChangeCharset(value) {
        console.log(`selected ${value}`);
        setFormValues('programCharset', value)
    }

    function onSearch(val) {
        console.log('search:', val);
    }

    return (
      <div>
        <div className="mb-2">
          <Form.Item name="couponType">
            <Radio.Group
              onChange={onChange}
              value={firstStep.couponType || value}
              style={{ width: "100%" }}
            >
              <div className="flex space-x-2">
                <div className="p-4 bg-white shadow-md">
                  <Radio value={1}>Bulk Codes</Radio>
                </div>
                <div className="p-4 bg-white shadow-md">
                  <Radio value={2}>Standalone code</Radio>
                </div>
              </div>
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="p-4 bg-white shadow-md">
          <Row gutter={24}>
            <Col lg={12}>
              <Form.Item
                label="Program Name"
                name="programName"
                rules={[
                  {
                    required: true,
                    message: "Please input your program name!",
                  },
                ]}
              >
                <input type="text" defaultValue={'P1'} onChange={(e)=> setFormValues('programName', e.target.value)} placeholder="Program name" />
              </Form.Item>
            </Col>
            <Col lg={8}>
              <div className="mt-8">
                <Form.Item name="autoUpdate" valuePropName="checked">
                  <Checkbox size="large" onChange={(e) => updateChange(e)}>
                    Auto Update
                  </Checkbox>
                </Form.Item>
              </div>
            </Col>
          </Row>
          {showUpdateInfo && (
            <Info>
              Auto update option will create a campaign that can be enhanced by
              new vouchers after the time of creation. This functionality is
              useful in case you publish multiple vouchers among customers and
              you want to ensure the number of available codes.
            </Info>
          )}
          <div className="lg:mb-8">
            <Row>
              <Col lg={24}>
                <Form.Item name="campaignJoinedType">
                  <Switch
                    //  checked={true}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                  <span className="pl-4">
                    Customers will be allowed to join campaign only once
                  </span>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="lg:mb-6">
            <Form.Item name="category" label="Select a category">
              <Select
                showSearch
                placeholder="Select a category"
                optionFilterProp="children"
                onChange={onChangeSearch}
                onSearch={onSearch}
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                size="large"
                style={{ width: "100%" }}
              >
                <Option value="category1">Category 1</Option>
                <Option value="category2">Category 2</Option>
              </Select>
            </Form.Item>
          </div>
          <div className="lg:mb-6">
            <Form.Item name="description" label="Description">
              <textarea
                placeholder="Description"
                showCount
                maxLength={100}
                onChange={(e)=> setFormValues('programDescription', e.target.value)}
                style={{width: '100%'}}
              />
            </Form.Item>
          </div>

          <div className="lg:mb-6">
            <Row gutter={24}>
              <Col lg={12}>
                {!showLimit ? (
                  <>
                    <span className="w-full -mb-4">Code Redemption Limit</span>
                    <Form.Item name="redemptionLimit">
                      <input type="number" placeholder="Code redemption limit" onChange={(e) =>  setFormValues('codeRedemptionLimit', e.target.value)}  style={{ width: "100%" }} />
                    </Form.Item>
                  </>
                ) : (
                  <div className="pt-2 text-lg font-medium text-gray-400 border-b border-gray-300 border-dashed">
                    Code Redemption Limit
                  </div>
                )}
              </Col>
              <Col lg={8}>
                <div className="mt-6">
                  <Form.Item name="checkLimit" valuePropName="checked">
                    <Checkbox size="large" onChange={(e) => limitChange(e)}>
                      Unlimited
                    </Checkbox>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
          <div className="lg:mb-6">
            <Row gutter={24}>
              <Col lg={12}>
                <span className="w-full -mb-4">Code Count</span>
                <Form.Item
                  // label={}
                  name="codeCount"
                  rules={[
                    {
                      required: true,
                      message: "Please input your code count!",
                    },
                  ]}
                >
                  <input type="number" onChange={(e) =>  setFormValues('programCount', e.target.value)} defaultValue={1} placeholder="Code count" style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col lg={8}>
                <div className="mt-6">
                  <Form.Item name="advanceSetting" valuePropName="checked">
                    <Checkbox size="large" onChange={(e) => settingChange(e)}>
                      Advanced code settings
                    </Checkbox>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {showAdvanceSetting && (
          <div className="p-4 mt-4 bg-white shadow-md">
            <div className="text-lg font-medium text-gray-600">
              Advance Setting
            </div>
            <div className="pt-6">
              <Row gutter={24}>
                <Col lg={12}>
                  <span className="w-full -mb-4">Charset</span>
                  <Form.Item
                    name="codeCount"
                    rules={[
                      {
                        required: true,
                        message: "Please input your code count!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Charset"
                      optionFilterProp="children"
                      onChange={onChangeCharset}
                      onSearch={onSearch}
                      allowClear
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      size="large"
                      style={{ width: "100%" }}
                    >
                      <Option value="alphabetic">Alphabetic</Option>
                      <Option value="alphabeticUppercase">
                        Alphabetic Uppercase
                      </Option>
                      <Option value="alphabeticLowercase">
                        Alphabetic Lowercase
                      </Option>
                      <Option value="number">Numbers</Option>
                      <Option value="custom">Custom</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <div className="mt-6">
                    <div className="pt-2 text-lg font-medium text-gray-400 border-b border-gray-300 border-dashed">
                      hfjdjfhdkjfhdfhdjf
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col lg={12}>
                  <span className="w-full -mb-4">Code Length</span>
                  <Form.Item name="codeLength">
                    <input type="text" placeholder="Code length" onChange={e => setFormValues('programCodeLength', e.target.value) } />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <div className="">
                    <span className="w-full -mb-4">Pattern</span>
                    <Form.Item name="pattern">
                      <input type="text" placeholder="Pattern" onChange={e => setFormValues('programPattern', e.target.value)} />
                      <div className="text-xs text-gray-400">
                        # - is replaced by random char
                      </div>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col lg={12}>
                  <span className="w-full -mb-4">Prefix</span>
                  <Form.Item name="prefix">
                    <input type="text" placeholder="Prefix" onChange={e => setFormValues('programPrefix', e.target.value)} />
                    <div className="text-xs text-gray-400">
                      Your voucher code will start with a given prefix
                    </div>
                  </Form.Item>
                </Col>
                <Col lg={8}>
                  <span className="w-full -mb-4">Postfix</span>
                  <Form.Item name="postfix">
                    <input type="text" placeholder="Postfix" onChange={e => setFormValues('programPostfix', e.target.value)} />
                    <div className="text-xs text-gray-400">
                      Your voucher code will end with a given postfix
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    );
}
export default CampainFirstStep;