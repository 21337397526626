import { Button, Col, Row, Steps, Form } from 'antd'
import React, { useEffect, useState } from 'react'
import AppContainer from '../../AppContainer'
import PageHead from '../../Comman/PageHead/Index'
import CampainFifthStep from '../FormSteps/CampainFifthStep';
import CampainFirstStep from '../FormSteps/CampainFirstStep';
import CampainFourthStep from '../FormSteps/CampainFourthStep';
import CampainSecondStep from '../FormSteps/CampainSecondStep';
import CampainThirdStep from '../FormSteps/CampainThirdStep';
import '../../Compaign/index.less';
import { fifthStep, firstStep, fourthStep, secondStep, thirdStep } from '../../../redux/voucherActions';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
const { Step } = Steps;

const CampaignCreator = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    let location = useLocation();
    // const vouchersData = useSelector(state => state.vouchers);
    const [current, setCurrent] = useState(0);
    const [value, setValue] = React.useState(1);
    const [formData, setFromData] = useState();

    console.log('state data from reducer', location, window.location.state);

    var campaignName = location?.state?.campaignName;

    const setFormValues = (name, value)=> {
        setFromData({...formData, [name]: value});
    }
    
    console.log('campaign data is here', formData);
    const onChange = e => {
        setValue(e.target.value);
    };

    const next = () => {
        // setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(0);
    };

    const onFinish = (values) => {
        console.log('Success:', values);
        switch(current){
            case 0:
                dispatch(firstStep(values))
                setFromData({})
                break;
            case 1:
                dispatch(secondStep(values))
                setFromData({})
                break;
            case 2:
                dispatch(thirdStep(values))
                setFromData({})
                break;
            case 3:
                dispatch(fourthStep(values))
                setFromData({})
                break;
            case 4:
                dispatch(fifthStep(values))
                history.push('/campaign/create/1234/dashboard')
                setFromData({})
                break;
            case 5:
                break;
            default:
                dispatch(firstStep(values));
                setFromData({})
        }
        setCurrent(current + 1);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const renderTitle = () => {
        switch (current) {
            case 0:
                return 'Program size';
            case 1:
                return 'Timeframe'
            case 2:
                return 'Discount value';
            case 3:
                return 'Metadata (optional)';
            case 4:
                return 'Summary';
            default:
                return '';
        }
    }

    const renderFormData = () => {
        switch (current) {
            case 0:
                return <CampainFirstStep onChange={onChange} value={value} setFormValues={setFormValues} />
            case 1:
                return <CampainSecondStep setFormValues={setFormValues} />
            case 2:
                return <CampainThirdStep setFormValues={setFormValues} />
            case 3:
                return <CampainFourthStep setFormValues={setFormValues} />
            case 4:
                return <CampainFifthStep setFormValues={setFormValues} />
            default:
                return <CampainFirstStep setFormValues={setFormValues} />;
        }
    }
    return (
        <AppContainer>
            <PageHead
                title="Create Campaign"
                breadcrumbs={[
                    { path: "/", breadcrumbName: "Home" },
                    {
                        path: "/",
                        breadcrumbName: "Create Campaign",
                    },
                ]}
                bgNone={true}
                subTitle="">
                <div className='h-screen'>
                    <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        layout='vertical'
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        hideRequiredMark
                    >
                        <div className='pb-4 text-xl font-semibold text-gray-600'>{current + 1}. {renderTitle()}</div>
                        <Row gutter={12}>
                            <Col lg={18}>
                                <div>
                                    <div className=''>
                                        {renderFormData()}
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className='p-4 bg-white shadow-md'>
                                    <div className="py-4 mb-6 text-lg font-medium border-b-2 border-gray-400">
                                        New {campaignName}
                                    </div>
                                    <Steps direction="vertical" current={current}>
                                        <Step onClick={() => setCurrent(0)} title={<span className='mb-2 text-lg font-medium'>Program size</span>} description={
                                            <div>
                                                <ul className="mb-2 list-disc" style={{ listStyleType: 'circle' }}>
                                                    <li className='mb-2' style={{ listStyleType: 'circle' }}>Bulk codes - up to thousands of random discount codes designed for single use by a limited group of customers (e.g., “ACME-5P13R” gives $25 off for the first 3 purchases, new customers from Warsaw only).</li>
                                                    <li style={{ listStyleType: 'circle' }}>Standalone code - A fixed-code discount designed for multiple uses (e.g., 10% off with “blackfriday” code).</li>
                                                </ul>
                                                <div className='mt-4 ml-2'>
                                                    {current === 0 &&
                                                        <Button type='' size='middle' onClick={() => next()} htmlType="submit">Next Step</Button>
                                                    }
                                                </div>
                                            </div>
                                        } />
                                        <Step onClick={() => setCurrent(1)} title={<span className='mb-2 text-lg font-medium'>Timeframe</span>} description={
                                            <div className='mt-4 ml-2'>
                                                {current === 1 &&
                                                    <Button type='' size='middle' onClick={() => next()} htmlType="submit">Next Step</Button>
                                                }
                                            </div>} />
                                        <Step onClick={() => setCurrent(2)} title={<span className='mb-2 text-lg font-medium'>Discount value</span>} description={
                                            <div className='mt-4 ml-2'>
                                                {current === 2 &&
                                                    <Button type='' size='middle' onClick={() => next()} htmlType="submit">Next Step</Button>
                                                }
                                            </div>} />
                                        <Step onClick={() => setCurrent(3)} title={<span className='mb-2 text-lg font-medium'>Metadata (optional)</span>} description={
                                            <div className='mt-4 ml-2'>
                                                {current === 3 &&
                                                    <Button type='' size='middle' onClick={() => next()} htmlType="submit">Next Step</Button>
                                                }
                                            </div>} />
                                        <Step onClick={() => setCurrent(4)} title={<span className='mb-2 text-lg font-medium'>Summary</span>} description={
                                            current === 4 && <div className='flex ml-2 lg:mt-4 md:space-y-1 md:flex-wrap lg:justify-between'>
                                                <div>
                                                    <Button type='' size='large' onClick={() => prev()}>Cancel</Button>
                                                </div>
                                                <div className='lg:-mt-4'>
                                                    <Button type='primary' htmlType='submit' size='large'>Submit</Button>
                                                </div>
                                            </div>}
                                        />
                                    </Steps>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </PageHead>
        </AppContainer>
    )
}

export default CampaignCreator
