import { Col, Row } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

const CampainFifthStep = () => {
  const summaryData = useSelector((state) => state.vouchers);

  return (
    <div className="p-8 bg-white">
    {summaryData?.first?.programName &&
      <div className="py-4 text-lg font-medium">
        $ {summaryData?.first?.programName}
      </div>
    }
      <Row gutter={6}>
        {summaryData?.first?.codeCount && (
          <Col lg={12}>
            <div className="py-4">
              <div className="text-lg">Codes count</div>
              <div>{summaryData?.first?.codeCount || ""}</div>
            </div>
          </Col>
        )}
        {summaryData?.first?.redemptionLimit && (
          <Col lg={12}>
            <div className="py-4">
              <div className="text-lg">Code redemption limit</div>
              <div>{summaryData?.first?.redemptionLimit || ""}</div>
            </div>
          </Col>
        )}

        {summaryData?.first?.category && (
          <Col lg={12}>
            <div className="py-4">
              <div className="text-lg">Category</div>
              <div>{summaryData?.first?.category || ""}</div>
            </div>
          </Col>
        )}

        {summaryData?.first?.description && (
          <Col lg={12}>
            <div className="py-4">
              <div className="text-lg">Description</div>
              <div>{summaryData?.first?.description || ""}</div>
            </div>
          </Col>
        )}
        <Col lg={12}>
          <div className="py-4">
            <div className="text-lg">Start date and time</div>
            <div>
              {moment(summaryData?.second?.startDate).format("MMM Do YY") || ""} |{" "}
              {moment(summaryData?.second?.startTime).format("LT") || ""}
            </div>
          </div>
        </Col>
        <Col lg={12}>
          <div className="py-4">
            <div className="text-lg">End date and time</div>
            <div>
              {moment(summaryData?.second?.endDate).format("MMM Do YY") || ""} |{" "}
              {moment(summaryData?.second?.endTime).format("LT") || ""}
            </div>
          </div>
        </Col>
        {summaryData?.third?.discountUSD && (
          <Col lg={12}>
            <div className="py-4">
              <div className="text-lg">Discount value</div>
              <div>$ {summaryData?.third?.discountUSD || ""}</div>
            </div>
          </Col>
        )}
        {summaryData?.third?.discountTo && (
          <Col lg={12}>
            <div className="py-4">
              <div className="text-lg">Discount to</div>
              <div>{summaryData?.third?.discountTo || ""}</div>
            </div>
          </Col>
        )}
        {summaryData?.third?.customerSegment && (
          <Col lg={12}>
            <div className="py-4">
              <div className="text-lg">In segment</div>
              <div>{summaryData?.third?.customerSegment || ""}</div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};
export default CampainFifthStep;
