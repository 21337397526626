// import { Button } from 'antd'
import React from 'react'
import AppContainer from '../AppContainer'
import PageHead from '../Comman/PageHead/Index'
import CompaignTemplate from './CompaignTemplate/CompaignTemplate';

const CreateCampainTypes = () => {
    return (
        <AppContainer>
            <PageHead
                title="Campaign Types"
                breadcrumbs={[
                    { path: "/", breadcrumbName: "Home" },
                    {
                        path: "/",
                        breadcrumbName: "Campaign Types",
                    },
                ]}
                bgNone={true}
                subTitle="">
                <CompaignTemplate />
            </PageHead>
        </AppContainer>
    )
}

export default CreateCampainTypes
