import { Checkbox, Col, DatePicker, Form, Input, Row, Select, TimePicker } from 'antd'
import moment from 'moment';
import React, { useState } from 'react'
const { Option } = Select;

const CampainSecondStep = ({setFormValues}) => {
    
    const [timeFrame, setTimeFrame] = useState(false)
    const [particularDay, setParticularDay] = useState(false)
    const [specificAmmount, setSpecificAmmount] = useState(false)

    function onChangeStart(value, dateString) {
        // console.log('Selected Time: ', value);
        // console.log('Formatted Selected Time: ', dateString);
        setFormValues('startDate', value)
        setFormValues('startTime', dateString)
    }
    function onChangeEnd(value, dateString) {
        // console.log('Selected Time: ', value);
        // console.log('Formatted Selected Time: ', dateString);
        setFormValues('endDate', value)
        setFormValues('endTime', dateString)
    }

    function onOk(value) {
        console.log('onOk: ', value);
    }

    const particular = [
        {
            value: 'Monday',
            name: 'Monday'
        },
        {
            value: 'Tuesday',
            name: 'Tuesday'
        },
        {
            value: 'Wednesday',
            name: 'Wednesday'
        },
        {
            value: 'Thursday',
            name: 'Thursday'
        },
        {
            value: 'Friday',
            name: 'Friday'
        },
        {
            value: 'Saturday',
            name: 'Saturday'
        },
        {
            value: 'Sunday',
            name: 'Sunday'
        }
    ];

    function handleChangeActiveHours(value) {
        console.log(`selected ${value}`);
        setFormValues('activeHoursSelection', value)
    }
    function handleChangeParticularDay(value) {
        console.log(`selected ${value}`);
        setFormValues('particularDay', value)
    }
    function handleChangePublishingTime(value) {
        console.log(`selected ${value}`);
        setFormValues('publishingTime', value)
    }

    return (
        <div>
            <div className='p-4 bg-white shadow-md'>
                <Row gutter={12}>
                    <Col lg={12}>
                        <span className='w-full -mb-4'>Start Date</span>
                        <Form.Item
                            name="startDate"
                        >
                            <DatePicker size="large" style={{ width: '100%' }} onChange={onChangeStart} onOk={onOk} />
                        </Form.Item>
                    </Col>
                    <Col lg={12}>
                        <span className='w-full -mb-4'>Start Time</span>
                        <Form.Item
                            name="startTime"
                        >
                            <TimePicker onChange={onChangeStart} style={{ width: '100%' }} size='large' defaultOpenValue={moment('00:00', 'HH:mm:a')} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col lg={12}>
                        <span className='w-full -mb-4'>End Date</span>
                        <Form.Item
                            name="endDate"
                        >
                            <DatePicker size="large" style={{ width: '100%' }} onChange={onChangeEnd} onOk={onOk} />
                        </Form.Item>
                    </Col>
                    <Col lg={12}>
                        <span className='w-full -mb-4'>End Time</span>
                        <Form.Item
                            name="endTime"
                        >
                            <TimePicker onChange={onChangeEnd} style={{ width: '100%' }} size='large' defaultOpenValue={moment('00:00', 'HH:mm:a')} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col lg={24}>
                        <Form.Item
                            name="timeTimeframe"
                            valuePropName="checked"
                        >
                            <Checkbox size='large' onChange={e => {setTimeFrame(e.target.checked); setFormValues('timeFrameChecked', e.target.checked)}}>Valid in this timeframe only</Checkbox>
                        </Form.Item>
                        {timeFrame &&
                            <div>
                                <Row gutter={12}>
                                    <Col lg={8}>
                                        <span className='w-full -mb-4'>Duration of</span>
                                        <Form.Item
                                            name="frameActiveHours"
                                        >
                                            <input type="text" onChange={e => setFormValues('frameActiveHours', e.target.value)} size="large" style={{ width: '100%' }} placeholder='Active after publishing for' />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8}>
                                        <span className='w-full -mb-4'>Time</span>
                                        <Form.Item
                                            name="frameTime"
                                        >
                                            <Select size='large' style={{ width: '100%' }} placeholder="Active only on" onChange={handleChangeActiveHours}>
                                                <Option value="Hour">Hour(s)</Option>
                                                <Option value="Day">Day(s)</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={8}>
                                        <span className='w-full -mb-4'>Repeat every</span>
                                        <Form.Item
                                            name="repeatEvery"
                                        >
                                            <input type="number" onChange={e => setFormValues('repeatEvery', e.target.value)} size="large" style={{ width: '100%' }} placeholder='Repeat every' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        }
                    </Col>
                    <Col lg={24}>
                        <Form.Item
                            name="particularDays"
                            valuePropName="checked"
                        >
                            <Checkbox size='large' onChange={e => {setParticularDay(e.target.checked); setFormValues('particularValues', e.target.checked)}}>Valid on particular days only</Checkbox>
                        </Form.Item>
                        {particularDay &&
                            <div className='lg:mb-6'>
                                <Select mode="tags" size='large' style={{ width: '100%' }} placeholder="Active only on" onChange={handleChangeParticularDay}>
                                    {particular?.map((item, i) => <Option key={i} value={item.value}>{item.name}</Option>)}
                                </Select>
                            </div>
                        }
                    </Col>
                    <Col lg={24}>
                        <Form.Item
                            name="specificTime"
                            valuePropName="checked"
                        >
                            <Checkbox size='large' onChange={e => {setSpecificAmmount(e.target.checked); setFormValues('specificTime', e.target.checked)}}>Keep valid for specific amount of time after publishing</Checkbox>
                        </Form.Item>
                        {specificAmmount &&
                            <div>
                                <Row gutter={12}>
                                    <Col lg={12}>
                                        <span className='w-full -mb-4'>Active after publishing for</span>
                                        <Form.Item
                                            name="activeHours"
                                        >
                                            <input type="number" onChange={e => setFormValues('activeHours', e.target.value)} size="large" style={{ width: '100%' }} placeholder='Active after publishing for' />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={12}>
                                        <span className='w-full -mb-4'>Time</span>
                                        <Form.Item
                                            name="time"
                                        >
                                            <Select size='large' style={{ width: '100%' }} placeholder="Active only on" onChange={handleChangePublishingTime}>
                                                <Option value="Hour">Hour(s)</Option>
                                                <Option value="Day">Day(s)</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        }
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default CampainSecondStep
