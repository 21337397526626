import React, { useState, useEffect } from "react";
import { List, Skeleton, Divider, Checkbox } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
const FilterDataList = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  var clickedData={}

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    fetch(
      "https://randomuser.me/api/?results=10&inc=name,gender,email,nat,picture&noinfo"
    )
      .then((res) => res.json())
      .then((body) => {
        var modifiedList = [];
        body.results.map(item => {
          console.log('item---', body.results);
          const itemList = {
            ...item,
            checkedItem: false,
          }
          modifiedList.push(itemList)
          setLoading(false);
        })
        setData([...data, modifiedList]);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadMoreData();
  }, []);
  console.log('after stting the data', data);


  function findMatchIndex(indexData) {
      console.log('checking index', indexData, clickedData);
    return indexData.email === clickedData?.email;
  }

  const addDataToList =(event, item, type)=>{
     clickedData = item;
      const checkExist = data.length === 0 ? data?.[0].findIndex(findMatchIndex) : data?.findIndex(findMatchIndex);
        if(event.target.checked){
            if(type === 'All'){
                var updatedData = data?.[0];
                data?.[0].map(item => 
                  updatedData.push({...item, checkedItem: item.checkedItem = true}),
                )
                setData(updatedData);
                setSelectedList([item]);
            } else if(type === '') {
              var updatedDataSingle = data?.[0];
              updatedDataSingle[checkExist].checkedItem = true;
                // data?.[0]?.[checkExist].checkedItem = true;
                // setData([...data[0]]);
                console.log('inside--add new', updatedDataSingle, data?.[0]?.[checkExist]?.checkedItem);
                setSelectedList([...selectedList, item]);
            }
        } else if(!event.target.checked && type === 'All'){
          var uncheckedData = data?.[0];
          data?.[0].map(item => 
            uncheckedData.push({...item, checkedItem: item.checkedItem = false}),
          )
          setData(uncheckedData);
          setSelectedList([item]);
        } else if(!event.target.checked && type === ''){
          var uncheckDataSingle = data?.[0];
          uncheckDataSingle[checkExist].checkedItem = false;
          setSelectedList([...selectedList, item]);
      }
  }

  return (
    <div>
      <div className="flex border-b-2 border-gray-200" style={{padding: "0 16px"}}>
        <div className="ml-2">
          <Checkbox onChange={(e) => addDataToList(e, data, 'All')} />
        </div>
        <div className="px-4 pb-6">Product</div>
      </div>
      <div
        id="scrollableDiv"
        style={{
          height: 400,
          overflow: "auto",
          padding: "0 16px",
        }}
      >
        {console.log('in jsx---', data)}
        {/* <InfiniteScroll
          dataLength={data?.[0]?.length}
          next={loadMoreData}
          hasMore={data?.[0]?.length < 50}
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
          scrollableTarget="scrollableDiv"
        > */}
          <List
            dataSource={data.length === 2 ? data?.[0] : data}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <List.Item.Meta
                  avatar={
                    <span className="ml-2">
                      <Checkbox
                        checked={item?.checkedItem}
                        onChange={(e) => addDataToList(e, item, '')}
                      />
                    </span>
                  }
                  title={<span>{item?.name?.last}</span>}
                  description={item?.email}
                />
                {/* <div>Content</div> */}
              </List.Item>
            )}
          />
        {/* </InfiniteScroll> */}
      </div>
    </div>
  );
};

export default FilterDataList;
