import React, { useEffect } from "react";
import { Form, Input, Button, Checkbox, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { loginAction, registerAction } from "../../redux/actions";
import './index.less'

function LoginRegisterForm({ value }) {

  const loginDetails = useSelector(state => state.rootReducer);

  const openNotificationWithIcon = type => {
    notification[type]({
      message: 'Something went wrong!',
      description:
        'Please try again later or check your credentials.',
    });
  };

  useEffect(()=>{
    if(!loginDetails?.isLogin){
      openNotificationWithIcon('error')
    }
  }, [loginDetails])

  const dispatch = useDispatch();

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (values) => {
    if (value === "login") {
      await dispatch(loginAction(values));
    } else if (value === "signup") {
      await dispatch(registerAction(values));
    }
  };

  return (
    <div className="text-left">
      <Form
        hideRequiredMark
        name="basic"
        layout="vertical"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {value !== "login" && (
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input size="large" placeholder="Enter username" />
          </Form.Item>
        )}

        <Form.Item
          name={"email"}
          label="Email"
          rules={[
            {
              type: "email",
            },
          ]}
        >
          <Input size="large"  placeholder="Enter email" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password size="large" placeholder="Enter password" />
        </Form.Item>

        {value !== "login" && (
          <Form.Item
            name="remember"
            valuePropName="checked"
          >
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" size="large" className="float-right" htmlType="submit">
            {value === "login" ? "Login" : "Register"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default LoginRegisterForm;
