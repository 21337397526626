import React, { useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
// import { Menu } from 'antd';
// import { HomeFilled } from '@ant-design/icons';
import './index.less'

function Sidebar({
  sidebarOpen,
  setSidebarOpen
}) {

  const location = useLocation();
  const { pathname } = location;
  const page = pathname.split('/')[1];

  const trigger = useRef(null);
  const sidebar = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="lg:w-64">
      {/* Sidebar backdrop (mobile only) */}
      <div className={`fixed inset-0 bg-white shadow-lg bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} aria-hidden="true"></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`absolute z-40 border-r-2 border-gray-200 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 flex-shrink-0 bg-white shadow-lg p-4 transition-transform duration-200 ease-in-out ${sidebarOpen ? 'translate-x-0' : '-translate-x-64'}`}
      >

        {/* Sidebar header */}
        <div className="flex justify-between lg:justify-center mb-4 pr-3 sm:px-2">
          
          {/* Logo */}
          <NavLink exact to="/" className="block">
            <div className="flex justify-between">
              <div>
                <svg width="50" height="50" viewBox="0 0 32 32">
                  <defs>
                    <linearGradient x1="28.538%" y1="20.229%" x2="100%" y2="108.156%" id="logo-a">
                      <stop stopColor="#A5B4FC" stopOpacity="0" offset="0%" />
                      <stop stopColor="#A5B4FC" offset="100%" />
                    </linearGradient>
                    <linearGradient x1="88.638%" y1="29.267%" x2="22.42%" y2="100%" id="logo-b">
                      <stop stopColor="#38BDF8" stopOpacity="0" offset="0%" />
                      <stop stopColor="#38BDF8" offset="100%" />
                    </linearGradient>
                  </defs>
                  <rect fill="#6366F1" width="32" height="32" rx="16" />
                  <path d="M18.277.16C26.035 1.267 32 7.938 32 16c0 8.837-7.163 16-16 16a15.937 15.937 0 01-10.426-3.863L18.277.161z" fill="#4F46E5" />
                  <path d="M7.404 2.503l18.339 26.19A15.93 15.93 0 0116 32C7.163 32 0 24.837 0 16 0 10.327 2.952 5.344 7.404 2.503z" fill="url(#logo-a)" />
                  <path d="M2.223 24.14L29.777 7.86A15.926 15.926 0 0132 16c0 8.837-7.163 16-16 16-5.864 0-10.991-3.154-13.777-7.86z" fill="url(#logo-b)" />
                </svg>
              </div>
              {/* <div className="text-extrabold text-white float-right text-right">Spoify</div> */}
            </div>
          </NavLink>
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden -mr-8 rounded-full bg-gray-200 shadow-lg h-10 text-gray-500 hover:text-gray-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
        </div>

        {/* Links */}
        <div>
          <h3 className="text-xs uppercase text-gray-500 font-semibold pl-3">Pages</h3>
          <ul className="mt-3">
            
              {/* Dashboard */}
                <li className={`px-3 py-3 rounded-sm last:mb-0 ${page === '' ? 'bg-blue-500' : 'hover:bg-gray-200'}`}>
                  {console.log('page', page)}
                  <NavLink exact to="/" className={`block text-gray-200 hover:text-white transition duration-150 ${page === '' && 'bg-blue-500 hover:text-gray-200'}`}>
                    <div className="flex flex-grow">
                      <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24">
                        <path className={`fill-current text-gray-400 ${page === '' && 'text-indigo-500'}`} d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z" />
                        <path className={`fill-current text-gray-600 ${page === '' && 'text-indigo-600'}`} d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z" />
                        <path className={`fill-current text-gray-400 ${page === '' && 'text-indigo-200'}`} d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z" />
                      </svg>
                      <span className="text-sm font-medium text-black">Dashboard</span>
                    </div>
                  </NavLink>
                </li>

              {/* Vouchers */}
              <li className={`px-3 py-3 rounded-sm last:mb-0 ${page === 'vouchers' ? 'bg-blue-500' : 'hover:bg-gray-200'}`}>
                  <NavLink exact to="/vouchers" className={`block text-gray-200 hover:text-white transition duration-150 ${page === 'vouchers' && 'hover:text-gray-200'}`}>
                    <div className="flex flex-grow">
                      <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24">
                        <path className={`fill-current text-gray-400 ${page === 'vouchers' && 'text-indigo-300'}`} d="M7 0l6 7H8v10H6V7H1z" />
                        <path className={`fill-current text-gray-600 ${page === 'vouchers' && 'text-indigo-500'}`} d="M18 7v10h5l-6 7-6-7h5V7z" />
                      </svg>
                      <span className="text-sm font-medium text-black">Vouchers</span>
                    </div>
                  </NavLink>
              </li>

              {/* Compain */}
              <li className={`px-3 py-3 rounded-sm last:mb-0 ${page === 'campaign' ? 'bg-blue-500' : 'hover:bg-gray-200'}`}>
                  <NavLink exact to="/campaign" className={`block text-gray-200 hover:text-white transition duration-150 ${page === 'campaign' && 'hover:text-gray-200'}`}>
                    <div className="flex flex-grow">
                      <img alt="" style={{height: '25px'}} src="https://img.icons8.com/dotty/80/000000/stack.png"/>
                      <span className="text-sm font-medium ml-3 text-black">Campains</span>
                    </div>
                  </NavLink>
              </li>
          </ul>
        </div>

      </div>
    </div>
  );
}

export default Sidebar;