import { ArrowDownOutlined } from "@ant-design/icons";
import { Button, Modal, Radio } from "antd";
import React, { useState } from "react";
import Info from "../../../../Comman/Info/Info";
import FilterDataList from "../FilterDataList/FilterDataList";

const AddOrder = ({ orderType, title }) => {
  const [visible, setVisible] = useState(false);
  const [selectedListItems, setSelectedListItems] = useState([]);

  return (
    <>
      <div className="flex space-y-4">
        <div
          className="pr-6 text-lg font-medium"
          style={{ lineHeight: "72px" }}
        >
          {title}
        </div>
        <div>
          <Button type="primary" onClick={() => setVisible(true)} size="large">
            Add
          </Button>
        </div>
      </div>
      <Modal
        title={
          <div className="py-4">
            <Radio.Group
              defaultValue="AllProducts"
              size="large"
              buttonStyle="solid"
            >
              <Radio.Button value="AllProducts">All products</Radio.Button>
              <Radio.Button value="SelectCollections">
                Select collections
              </Radio.Button>
            </Radio.Group>
          </div>
        }
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={1000}
      >
        <div>
          <div className="py-4 border-b-2 border-gray-200">
            <div>Filter</div>
          </div>
          <div className="my-4">
            <Info>
              <ArrowDownOutlined />
              Choose products (up to 20) from the list to create static
              collection (manually grouped products). Otherwise the created
              collection will be auto-updated with your product catalog changes
            </Info>
          </div>
          <div>
            <FilterDataList setSelectedListItems={setSelectedListItems} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddOrder;
