/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    ArrowDownOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    PlusOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input, Radio, Row, Select, Tooltip } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import DiscountCard from "../../../../Comman/DiscountCard/DiscoutCard";
import Info from "../../../../Comman/Info/Info";
import AddOrder from "../AddOrder/AddOrder";
import AttributeMatch from "../Attribute/AttributeMatch";
import Budget from "../Budget/Budget";
import DiscountToFilter from "../DiscountToFilter/DiscountToFilter";
const { Option } = Select;

const FreeShipping = ({ setFormValues }) => {
    const [selectedDiscountTo, setSelectedDiscountTo] = useState("missingItem");
    const [segmentSelection, setSegmentSelection] = useState("Customer activity");
    const [createValidation, setCreateValidation] = useState(true);
    const [selectedValidation, setSelectedValidation] = useState("Customer activity");


    const changeDiscount = (e) => {
        setSelectedDiscountTo(e.target.value)   
        if (e.target.value !== 'wholeCart') {
            setCreateValidation(false)
        } else {
            setCreateValidation(true)
        }
    }



    const renderInfoText = "This type of deal adds items to the cart only if a customer has fewer free items than offered by the voucher.";

    const validationType = [
        {
            imageSrc: "https://img.icons8.com/ios-filled/50/000000/user.png",
            discountType: "Customer activity",
            subTitle: "(e.g. cart abandoned)",
        },
        {
            imageSrc: "https://img.icons8.com/glyph-neue/64/000000/stack.png",
            discountType: "Attributes match",
            subTitle: "(e.g. 10% off)",
        },
        {
            imageSrc:
                "https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/64/000000/external-dollar-banking-and-finance-kiranshastry-lineal-kiranshastry-6.png",
            discountType: "Budget",
            subTitle: "(e.g. total order value)",
        },
        {
            imageSrc: "https://img.icons8.com/dotty/80/000000/create-order.png",
            discountType: "Order",
            subTitle: "(e.g. total amount)",
        },
    ];

    const renderValidationType = useCallback(() => {
        switch (selectedValidation) {
            case "Customer activity":
                return (
                    <Row gutter={12}>
                        <Col lg={12}>
                            <span className="w-full">Condition</span>
                            <Form.Item name="customerSegment">
                                <Select
                                    size="large"
                                    style={{ width: "100%" }}
                                    placeholder="Active only on"
                                    onChange={(e) => {setSegmentSelection(e); setFormValues('freeShippingSegment', e.target.value)}}
                                >
                                    <Option value="inSegment">Customer is in segment</Option>
                                    <Option value="notInSegment">
                                        Customer is not in segment
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12}>
                            <div className="mt-5">
                                <Form.Item name="">
                                    <Button type="primary" size="large">
                                        Add
                                    </Button>
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                );
            case "Attributes match":
                return <AttributeMatch setFormValues={setFormValues} />;
            case "Budget":
                return (
                    <>
                        <Budget 
                            title="Total orders value" 
                            show={false} 
                            fieldName={'freeShippingBudgetTotalOrder'}
                            setFormValues={setFormValues}
                        />
                        <Budget
                            title="Total discounted amount"
                            fieldName={"freeShippingTotalDiscount"}
                            show={false}
                            setFormValues={setFormValues}
                        />
                        <Budget
                            title="Total number of redemptions"
                            fieldName={"freeShippingTotalRedemption"}
                            show={false}
                            setFormValues={setFormValues}
                        />
                        <div className="mt-6">
                            <div className="pb-4 text-lg font-medium">
                                Redemptions per customer
                            </div>
                            <div className="border-l-2 border-gray-400 border-dashed">
                                <div className="pl-4">
                                    <Budget
                                        title="Per code"
                                        fieldName={"freeShippingCustomerPerCode"}
                                        show={false}
                                        setFormValues={setFormValues}
                                    />
                                    <Budget
                                        title="Per code per day"
                                        fieldName={"freeShippingCustomerPerCodePerDay"}
                                        show={false}
                                        setFormValues={setFormValues}
                                    />
                                    <Budget
                                        title="In campaign"
                                        fieldName={"freeShippingCustomerCampaign"}
                                        setFormValues={setFormValues}
                                        show={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                );
            case "Order":
                return (
                    <>
                        <div className="">
                            <Row gutter={6}>
                                <Col lg={8}>
                                    <div className="pt-2 text-lg font-semibold">Total amount</div>
                                </Col>
                                <Col lg={6}>
                                    <div className="pl-2">
                                        <Form.Item name="orderCondition">
                                            <Select
                                                size="large"
                                                style={{ width: "100%" }}
                                                placeholder="Condition"
                                                onChange={(e) => setFormValues('freeShippingCondition', e.target.value)}
                                            >
                                                <Option value="isLessThan">is less than</Option>
                                                <Option value="IsExactly">is exactly</Option>
                                                <Option value="isMoreThan">is more than</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="pl-2">
                                        <Form.Item name="freeShippingValue">
                                            <input type="number" onChange={e => setFormValues('freeShippingValue')} placeholder="Value (USD)" />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="pl-2">
                                        <Button type="primary" size="large">
                                            Add
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <div>
                                <AddOrder orderType="Any" title="Any order item" />
                                <AddOrder orderType="Every" title="Every order item" />
                            </div>
                        </div>
                    </>
                );
            default:
                return "whole Cart – Discount will be applied to the total order amount.";
        }
    }, [selectedValidation]);

    return (
        <>
            <div className="p-4 bg-white">
                <Row gutter={12}>
                    <Col lg={12}>
                        <span className="w-full -mb-4">Unit Value</span>
                        <Form.Item name="unitValue">
                            <input
                                type="number"
                                onChange={e => setFormValues('freeShippingUnitValue', e.target.value)}
                                size="large"
                                style={{ width: "100%" }}
                                placeholder="Unit Value"
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={11}>
                        <span className="w-full -mb-4">Select unit type</span>
                        <Form.Item name="unitType">
                            <Select
                                allowClear
                                size="large"
                                style={{ width: "100%" }}
                                placeholder="Select unit"
                                onChange={(e) => setFormValues('freeShippingUnitType', e.target.value)}
                            >
                                <Option value="unit1">Unit 1</Option>
                                <Option value="unit2">
                                    Unit 2
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    {/* <Col lg={2}>
                        <div className="mt-6 rounded-full cursor-pointer">
                            <Tooltip title="Add">
                                <Button type="" shape="circle" icon={<PlusOutlined />} />
                            </Tooltip>
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="mt-6 rounded-full cursor-pointer">
                            <Tooltip title="Select">
                                <Button type="" shape="circle" icon={<SearchOutlined />} />
                            </Tooltip>
                        </div>
                    </Col> */}
                    <Col lg={24}>
                        <div className="w-full mb-2 text-lg">Discount effect</div>
                        <Form.Item name="discountTo">
                            <Radio.Group
                                size="large"
                                defaultValue={'missingItem'}
                                onChange={(e) => {changeDiscount(e); setFormValues('freeShippingMissingItem', e.target.value)}}
                                buttonStyle="solid"
                            >
                                <Radio.Button value="missingItem">Add missing items</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col lg={24}>
                        {selectedDiscountTo && (
                            <Info>
                                <span>Apply to </span> {renderInfoText}
                            </Info>
                        )}
                    </Col>
                </Row>
            </div>
            <div className="mb-8">
                {selectedDiscountTo === "missingItem" && (
                    <div className="px-4 py-8 mt-4 bg-white">
                        <Info>
                            Validation rules help you define promotions’ eligibility
                            conditions based on audience, order structure, order volume,
                            budget constraints,and others - <a href="#">read more</a>
                        </Info>
                    </div>
                )}
                {createValidation && (
                    <div className="flex px-4 py-8 mt-4 bg-white">
                        <div>
                            <Button
                                type=""
                                onClick={() => setCreateValidation(false)}
                                size="large"
                            >
                                Create validation rule
                            </Button>
                        </div>
                        <div className="lg:ml-4">
                            <Button type="" size="large">
                                Select existing validation rule
                            </Button>
                        </div>
                    </div>
                )}

                {!createValidation && (
                    <div className="flex px-4 py-8 mt-4 bg-white">
                        <div className="flex flex-wrap">
                            {validationType.map((item, i) => (
                                <DiscountCard
                                    i={i}
                                    item={item}
                                    selectedDiv={selectedValidation}
                                    onClick={() => setSelectedValidation(item.discountType)}
                                />
                            ))}
                        </div>
                    </div>
                )}

                {!createValidation && (
                    <div className="px-4 py-8 mt-4 bg-white">
                        {renderValidationType()}
                    </div>
                )}
            </div>
        </>
    );
};

export default FreeShipping;
