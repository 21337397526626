import React from "react";
// import imageUrl from '../../images/minor.png'

const LoginWrapper = ({ children, setValue, value }) => {
  return (
    <div className="flex flex-wrap w-full">
      {/* Login Section */}
      <div className="flex flex-col w-full h-screen lg:w-1/2" >
        <div className="flex justify-center pt-12 md:justify-start md:pl-12 md:-mb-24">
          <a href="#" className="p-4 text-xl font-bold text-white bg-black">
            Spiofy
          </a>
        </div>
        <div style={{backgroundImage: 'url(../../images/minor.png)'}} className="flex flex-col justify-center px-20 pt-8 my-auto md:justify-start md:pt-0 md:px-24 lg:px-32">
          <p className="py-6 text-3xl font-bold text-center">
            {value === "login" ? "Welcome back!" : "Register here."}
          </p>
          {children}
          <div className="pt-12 pb-12 text-center">
            <p>
              {value !== "login"
                ? "Already have account?"
                : "Do not have an account?"}{" "}
              <a
                href="#"
                onClick={() => {
                  if (value === "login") {
                    setValue("signup");
                  } else {
                    setValue("login");
                  }
                }}
                className="font-semibold underline"
              >
                {value !== "login" ? "Login" : "Register here."}
              </a>
            </p>
          </div>
        </div>
      </div>
      {/* Image Section */}
      <div className="w-1/2 shadow-2xl">
        <img
          className="hidden object-cover w-full h-screen md:block"
          src="https://source.unsplash.com/IXUM4cJynP0"
        />
      </div>
    </div>
  );
};

export default LoginWrapper;
