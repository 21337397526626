/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Table, Skeleton, Avatar, Tag, Popconfirm, Button } from 'antd';
import styles from './Users.css';
import { useDispatch, useSelector } from "react-redux";
import AppContainer from '../../AppContainer';
import { fetch, remove, sendEmail } from '../services/users';
import EditUserModal from './EditUserModal';
import PageHead from '../../Comman/PageHead/Index';
import { Link } from 'react-router-dom';

function Users({ list: loading, total, page: current }) {

  const dispatch = useDispatch();

  const [dataSource, setDataSource] = useState();
  // const [visible, setVisible] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)
  const [editableData, setEditableData] = useState({})


  const data = useSelector(state => state.rootReducer.data)

  const fetchData = async (values) => {
    await dispatch(fetch(""));
  }
  useEffect(() => {
    fetchData();
    setEditableData(editableData)
  }, [])

  useEffect(() => {
    if (data?.length > 0) {
      setDataSource(data)
    }
  }, [data])

  const deleteHandler = async (id, values) => {
    if (id)
      await dispatch(remove(id?._id))
  }

  const emailHandler = async (_id, values) => {
    if (_id?._id) {
      await dispatch(sendEmail(_id))
    }
  }

  const editHandler = async (id, values) => {
    console.log("id and values", id, values);
    setEditableData(id || values)
  }

  // const createHandler = async (values) => {
  //   setVisible(!visible)
  // }

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: text => <a href="#"><Avatar className="mr-2 bg-blue-600 uppercase text-lg pt-1" size="large">{text.charAt(0)}</Avatar>{text}</a>,
    },
    {
      title: 'Voucher',
      dataIndex: 'voucher',
      key: 'voucher',
      render: text => <span>{<Tag color="green">{text}</Tag> || "---"}</span>,
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
      render: text => <span>{<Tag color="orange">{text + '%'}</Tag> || "---"}</span>,
    },
    {
      title: 'Operations',
      key: 'operation',
      render: (text, record) => (
        <span className={styles.operation}>
          <Button type="dashed" onClick={() => { editHandler(record, text); setUpdateModal(true); }} className="mr-2">Edit</Button>
          <Popconfirm title="Confirm to delete?" onConfirm={deleteHandler.bind(text._id, text)}>
            <Button type="dashed">Delete</Button>
          </Popconfirm>
          <Popconfirm title="Are you sure?" onConfirm={emailHandler.bind('', text)}>
            <Button type="dashed" className="ml-2" >Send Email</Button>
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <AppContainer>
      <Skeleton loading={loading}>
        <EditUserModal
          visible={updateModal}
          record={editableData}
          onOk={setUpdateModal}
        />
        <div className={styles.normal}>
          <div>
            <PageHead
              title="Vouchers"
              breadcrumbs={[
                { path: "/", breadcrumbName: "Home" },
                {
                  path: "/",
                  breadcrumbName: "Vouchers",
                },
              ]}
              bgNone={true}
              button={
                <Link to="/campaign">
                  <Button type="primary" size="large">
                    Create Voucher
                  </Button>
                </Link>
              }
              subTitle="Get all the vouchers here"
            >
              <div className="p-4">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={dataSource}
                  rowKey={(record) => record.id}
                  pagination={false}
                />
              </div>
            </PageHead>

            {/* <Pagination
          className="ant-table-pagination"
          total={total}
          current={current}
          pageSize={PAGE_SIZE}
          onChange={pageChangeHandler}
        /> */}
          </div>
        </div>
      </Skeleton>
    </AppContainer>
  );
}

export default Users;
